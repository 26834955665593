.student-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #85FFBD;
    background-image: linear-gradient(45deg, #85FFBD 0%, #ffffff 100%);
}

img {
    max-width:40%;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 300px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
    padding: 10px;
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button[type="submit"] {
    padding: 10px 20px;
    background-color: #0077ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #0057cb;
}

@media (max-width: 600px) {
    .student-form {
        margin-top: -20px;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    form{
        width: 100%;
    }

    .student-form h1 {
        font-size: 1.5rem;
    }
    .student-form h1 {
        font-size: 1.5rem;
        margin-top: 20px;
    }
}