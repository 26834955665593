.feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #ffffff, #84b2e1);
    border-radius: 8px;
    padding: 24px;
    margin: 20px;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
    max-width: 400px;
}

.feature-card__image {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
}

.feature-card__heading {
    font-size: 24px;
    font-weight: bold;
    color: rgb(60, 190, 238);
    margin-bottom: 16px;
}

.feature-card__content {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
}