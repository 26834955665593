.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 0 1rem;
    height: 3.5rem;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-logo {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.navbar-toggler {
    display: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.navbar-toggler:focus {
    outline: none;
}

.navbar-toggler-icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
}

.navbar-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #333;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.navbar-menu.show {
    opacity: 1;
    visibility: visible;
}

.navbar-item {
    margin: 0 1rem;
}

.navbar-link {
    color: #ffffff;
    margin: 10px;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease-in-out;
}

.navbar-link:hover {
    color: #27a2e0;
}

@media (max-width: 768px) {
    .navbar {
        flex-wrap: wrap;
        padding: 0.5rem 1rem;
    }

    .navbar-toggler {
        display: block;
    }

    .navbar-menu {
        flex-direction: column;
        align-items: flex-start;
        top: 3.5rem;
    }

    .navbar-item {
        margin: 1rem 0;
    }

    .navbar-link {
        font-size: 1.25rem;
        
    }
}

@media (min-width: 769px) {
    .navbar-menu {
        display: flex !important;
        opacity: 1 !important;
        visibility: visible !important;
        position: static !important;
        flex-direction: row !important;
        justify-content: space-between;
        align-items: center !important;
        width: auto !important;
        background-color: transparent !important;
    }

    .navbar-item {
        margin: 0;
    }

    .navbar-link {
        font-size: 1rem;
    }
}