
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
    justify-content: center;
    width: 80%;
    max-height: 600px;
    max-width: 443px;
    padding: 10px;
    background-color: #e3fffb;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.contact-label {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 5px;
}

.contact-input,
.contact-textarea {
    height: 40px;
    padding: 10px;
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-textarea {
    height: 90px;
}

.contact-button {
    padding: 10px 20px;
    background-color: #0077ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

@media only screen and (min-width: 768px) {
    .contact-container {
        padding: 2rem;
    }

    .contact-header {
        font-size: 3rem;
    }

    .contact-form {
        width: 80%;
    }
}