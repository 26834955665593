.services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
    .services-container {
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
    }
}