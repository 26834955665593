.about-container {
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
}

.about-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    text-align: center;
}

.about-container p {
    font-size: 1.5rem;
    line-height: 2;
    margin-bottom: 30px;
}